<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <table class width="680px" style="margin-left: 30px;">
          <tr>
            <td>
              <label class style="font-size: 2em;">{{ $props.name.toUpperCase().replace("-", " ") }}</label>
            </td>
            <td align="right">
              <!-- <img class="" src="{{ asset('static/golo-crm-logo.png') }}" style="width: 200px;"> -->
            </td>
          </tr>
        </table>
        <table class width="680px" style="margin-left:15px;">
          <tr>
            <td>
              <hr
                style="height: 1px;
                                    margin: 2px 1px;
                                    overflow: hidden;
                                    border-bottom: 1px solid #ccc;
                                    -webkit-print-color-adjust: exact;"
              />
            </td>
          </tr>
        </table>
        <table class="table1" width="680px" style="margin-left: 20px;">
          <tr>
            <td width="60%" style="vertical-align:top;line-height: 1.6">
                    <b>To,</b><br>
                    {{ $props.item.customer.full_name }}<br>
                    {{ $props.item.customer.full_address }}<br>
                    {{ $props.item.customer.number }}
            </td>
            <td width="38%;line-height: 1.6">
              <b>Ref No.:</b>
              {{ $props.item.ref_no }}
              <br />
              <b>Issue Date:</b>
              {{ $props.item.issue_date_formatted }}
              <br />
              <b>Valid Till:</b>
              {{ $props.item.valid_till_formatted }}
              <br />
            </td>
          </tr>
        </table>
        <br />
        <br />
        <table
          class="table table1"
          style="border-collapse: collapse;
                                                margin-left: 20px;"
          width="680px"
        >
          <thead>
            <tr style="border-bottom: 1px solid #ccc">
              <th style="width: 25px">
                <b>SL.</b>
              </th>
              <th style="text-align: left">
                <b>Item Description</b>
              </th>
              <th style="width: 70px; text-align: center">
                <b>Qty</b>
              </th>
              <th style="width: 70px; text-align: center">
                <b>Price</b>
              </th>
              <th style="width: 100px; text-align: right">
                <b>Amount</b>
              </th>
            </tr>
          </thead>
          <tbody id="inv-detail">
            <tr v-for="(p, i) in $props.item.products" :key="i">
              <td v-if="approval && $route.params.item == 'quotation'">
                <v-checkbox
                  :value="p.id"
                  color="success"
                  v-model="form.products"
                ></v-checkbox>
                <!-- <v-checkbox v-model="products" :value="p.product_id"></v-checkbox> {{p.id}} -->
              </td>
              <td v-else>{{ i + 1 }}</td>
              <td>{{ p.product.name }}</td>
              <td style="text-align: center">{{ p.quantity }}</td>
              <td style="text-align: center">{{ p.price_formatted }}</td>
              <td style="text-align: right">{{ p.amount_formatted }}</td>
            </tr>
            <tr>
              <td colspan="5" style="border-bottom: 1px solid #ccc"></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="4" style="text-align: right">Sub Total:</th>
              <th style="text-align: right">{{$props.item.subTotal_formatted}}</th>
            </tr>
            <tr>
              <th colspan="4" style="text-align: right">Discount:</th>
              <th style="text-align: right">{{$props.item.discount_formatted}}</th>
            </tr>
            <tr v-for="(t, i) in $props.item.tax" :key="i">
                <th colspan="4" style="text-align: right">
                    {{ t.tax.name }} ({{ t.tax.percentage_formatted }}):
                </th>
                <th style="text-align: right">
                    {{ getTaxAmount($props.item.subTotal, t.tax_percentage) }}
                </th>
            </tr>
            <tr>
              <th colspan="4" style="text-align: right">Grand Total: &nbsp;</th>
              <th style="text-align: right">{{$props.item.total_formatted}}</th>
            </tr>
          </tfoot>
        </table>
        <br />
        <table
          class="table table1"
          style="border-collapse: collapse;
        margin-left: 20px;"
          width="680px"
        >
          <tr>
            <th style="text-align: left">Thank you for your Business!</th>
            <th style="text-align: right">
              -----------------------
              <br />Authorised Singature
            </th>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right">
              <i style="font-size: 10px">Note: Terms & conditions apply*</i>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <v-row v-if="approval" class="print">
      <v-col>
        <v-btn color="primary" elevation="2" @click="submit">Approve</v-btn>
        <v-btn class="ml-4" color="primary" elevation="2" @click="print">Print</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="otpDialog" max-width="450px">
      <otp :quotation="$props.item" @otpVerified="otpVerified" />
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import otp from "@/components/Quotation/otp-screen";
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      taxs: [],
      approval: false,
      otpDialog: false,
      form:{
        products: [],
      },
      showOtp: 0
    };
  },
    components: { otp },
  mounted() {
    this.getTaxes();
    if (
      this.$route.params.approve &&
      this.$route.params.approve == "approval"
    ) {
      this.approval = true;
      this.getSetting();
    }
  },
  methods: {
    getSetting() {
      let url = "";
      if (this.$route.params.item == "quotation") {
        url = "public/quotation/otp";
      } else if (this.$route.params.item == "purchase-order") {
        url = "public/po/otp";
      }
      util
        .http({
          url: url,
          method: "get"
        })
        .then(res => {
          this.showOtp = res.data.data;
        });
    },
    print() {
      window.print();
    },
    getTaxes() {
      util
        .http({
          url: "tax/public",
          method: "get"
        })
        .then(res => {
          if (res.data.status == 1) {
            this.taxs = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
    getName(tax) {
      let a = this.taxs.find(t => t.id == tax.id);
      if (a) {
        return a.name;
      }
    },
    getTaxAmount(subtotal, tax) {
        let t = subtotal * (tax/100);
        return '$'+t.toFixed(2);
    //   let a = this.taxs.find(t => t.id == tax.id);
    //   if (a) {
    //     return (this.subTotal * a.percentage) / 100;
    //   }
    },
    changeDateFormat(date) {
      return util.changeDateFormat(date);
    },
    submit() {
      if (this.showOtp == 1) {
        this.otpDialog = true;
      } else {
        this.otpVerified();
      }
    },
    otpVerified() {
      this.otpDialog = false;
      util
        .http({
          url:
            this.$route.params.item + "/public/approve/" + this.$props.item.id,
          method: "post",
          data: this.form
        })
        .then(resp => {
          if (resp.data.status == 1) {
            util.notify(1, "Approved");
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    }
  },
  computed: {
    // subTotal() {
    //   let total = 0;
    //   this.$props.item.products.forEach(item => {
    //     total += item.quantity * item.price;
    //   });
    //   return total;
    // },
    // taxTotal() {
    //   let total = 0;
    //   this.$props.item.tax.forEach(tax => {
    //     total += this.getTaxAmount(tax);
    //   });
    //   return total;
    // },
    // grandTotal() {
    //   return this.subTotal - this.$props.item.discount + this.taxTotal;
    // }
  }
};
</script>

<style scoped>
table tr th,
table td {
  padding: 10px;
}

.table1 tr td,
.talbe1 tr th {
  font-size: 16px;
}
</style>