<template>
  <div>
    <div class="background-image-section">
      <div class="d-flex justify-content-center background-color-section">
        <div class="login-card ma-auto">
          <div class="card-head row justify-content-center mb-3">
            <!-- <img src="../assets/images/logo2 2.png" class="img-fluid" /> -->
          </div>
          <div id="card" class="pb-2">
            <div id="card-content">
              <div id="card-title">
                <!-- <img src="../assets/images/Group 7.png" class="img-fluid" /> -->
                <h4 class="font-weight-bold d-flex justify-center mt-8">
                  Verify OTP
                </h4>
              </div>

              <v-form @submit.prevent="onSubmit">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-otp-input
                        length="6"
                        type="number"
                        v-model="data.otp"
                        :rules="[rules.required, rules.number]"
                      ></v-otp-input>
                    </v-col>
                    <v-col class="d-flex justify-space-around">
                      <v-btn color="success" class="mr-4" type="submit">
                        Submit
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-4 float-right"
                        v-on:click="sendOtp()"
                      >
                        Resend OTP
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    quotation: {
      required: true,
      type: Object,
    },
  },
  data: function() {
    return { rules: util.validation, basic: util.input.basic, data: {} };
  },
  mounted() {
    this.sendOtp();
  },
  methods: {
    sendOtp() {
      util
        .http({
          url: "/quotation/send-otp",
          method: "post",
          data: { quotation_id: this.$props.quotation.id },
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            util.notify(1, "OTP Sent");
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    },
    onSubmit() {
      this.data.quotation_id = this.$props.quotation.id;
      util
        .http({
          url: "/quotation/verify-otp",
          method: "post",
          data: this.data,
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            util.notify(1, "OTP Verified");
            this.$emit("otpVerified");
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    },
  },
};
</script>
